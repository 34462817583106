/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 */

(() => {
  'use strict'

  const getStoredTheme = () => localStorage.getItem('theme')
  const setStoredTheme = theme => localStorage.setItem('theme', theme)

  const getPreferredTheme = () => {
    const storedTheme = getStoredTheme()
    if (storedTheme) {
      return storedTheme
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }

  const setTheme = theme => {
    if (theme === 'auto') {
      document.documentElement.setAttribute('data-bs-theme', (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'))
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  setTheme(getPreferredTheme())

  const showActiveTheme = (theme) => {
    let themeSwitcher = document.querySelector('#btnSwitch')
    let checkboxLabel = document.querySelector('label[for="btnSwitch"]')

    let themeSwitcherMobile = document.querySelector('#btnSwitchMobile')
    let checkboxLabelMobile = document.querySelector('label[for="btnSwitchMobile"]')

    if (!themeSwitcher || !checkboxLabel || !themeSwitcherMobile || !checkboxLabelMobile) {
      return
    }

    if (theme=='light') {
      checkboxLabel.innerHTML = "Light Mode"
      themeSwitcher.checked = false;

      checkboxLabelMobile.innerHTML = "Light Mode"
      themeSwitcherMobile.checked = false;
    }
    else if (theme=='dark') {  
      checkboxLabel.innerHTML = "Dark Mode"
      themeSwitcher.checked = true;

      checkboxLabelMobile.innerHTML = "Dark Mode"
      themeSwitcherMobile.checked = true;
    }
  }

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    const storedTheme = getStoredTheme()
    if (storedTheme !== 'light' && storedTheme !== 'dark') {
      setTheme(getPreferredTheme())
    }
  })

  function addEventlistener() {
      var theme = document.documentElement.getAttribute('data-bs-theme')  
      theme = (theme === 'dark') ? 'light' : 'dark';

      setStoredTheme(theme)
      setTheme(theme)
      showActiveTheme(theme)
      console.log('switched theme: ' + theme)
    };

  window.addEventListener('turbo:load', () => {
    showActiveTheme(getPreferredTheme())
    
    var mobile=document.getElementById('btnSwitchMobile')
    if(mobile) {
      mobile.addEventListener("click", addEventlistener);
    }
    var desktop=document.getElementById('btnSwitch')
    if(desktop) {
      desktop.addEventListener("click", addEventlistener);
    }
  })
})();


